import React from "react"
import { Box } from "theme-ui"

//Base size to keep all layers aligned easier
const bs = x => `${x * 0.35}rem`

const styles = {
  wrapper: {
    position: `relative`,
    zIndex: 2,
    textAlign: `center`,
    mb: bs(3),
  },
  circle: ({ width }) => ({
    width: [bs(30), bs(30)],
    height: `full`,
    maxWidth: width,
    borderRadius: `full`,
    position: `absolute`,
    transform: `translate(-50%)  scale(0.98)`,
    left: `50%`,
    top: bs(3),
    bg: `alpha`,
  }),
  arc: ({ width }) => ({
    width: [bs(30), bs(30)],
    height: `full`,
    maxWidth: width,
    borderRadius: `full`,
    position: `absolute`,
    zIndex: 2,
    left: `50%`,
    transform: `translate(-50%)`,
    marginTop: `-0.55rem`,
    ml: bs(-2),
    boxShadow: t => `
			${bs(2)}
			1.6rem
			${t.colors.omegaLight}
		`,
  }),
  imageWrapper: ({ width }) => ({
    width: [bs(30), bs(30)],
    maxWidth: width,
    position: `relative`,
    mx: `auto`,
    "> div": {
      borderRadius: `0 0 9999px 9999px`,
    },
  }),
  imageWrapperSimple: ({ width }) => ({
    height: `53px`,
    width,
    bg: `omegaLight`,
    display: `inline-block`,
    verticalAlign: `middle`,
    borderRadius: `full`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLight`,
    overFlow: `hidden`,
    // filter: `grayscale(1)`,
    opacity: 0.9,
    mr: 3,
    ":hover": {
      opacity: 1,
    },
    "> div": {
      borderRadius: `full`,
    },
  }),
  imageStyle: {
    borderRadius: `30%`,
  },
  imageStyleSimple: {
    borderRadius: `50%`,
  },
  bgAvatar: {
    boxSizing: `border-box`,
    minWidth: `0px`,
    width: `100%`,
    height: `100%`,
    backgroundImage: `url(https://flexiblog-agency.web.app/static/memphis-b8f9c89c64b0fa18da29f0f89b42f71f.png)`,
    backgroundSize: `8rem`,
    position: `absolute`,
    opacity: `0.15`,
    top: `0px`,
    left: `0px`,
    margin: `0px`
  }
}

const Avatar = ({ avatar, simple, bgWidth = "60", width = "150", bgShow=false }) => {
  if (!avatar) return null

  return simple ? (
    <Box sx={styles.imageWrapperSimple({ bgWidth })}>
      <img style={styles.imageStyleSimple} src={avatar} width={width} alt="" />
    </Box>
  ) : (
    <Box sx={styles.wrapper}>
      <Box>
        {bgShow ? (<Box sx={styles.bgAvatar}></Box>) : null}
        <Box sx={styles.circle({ width })}></Box>
        <Box sx={styles.arc({ width })}></Box>
        <Box sx={styles.imageWrapper({ width })}>
          <img style={styles.imageStyle} src={avatar} width={width} alt="" />
        </Box>
      </Box>
    </Box>
  )
}

export default Avatar
