import React from "react"
import { Text, useThemeUI, get } from "theme-ui"
import { buildResponsiveVariant as rv } from "@components/utils/"
import "./styles.css"

const styles = {
  excerpt: {
    flex: `auto`,
    margin: `auto`,
  },
}

const CardBodyExcerpt = ({ variant, excerpt, omitExcerpt, show }) => {

  const context = useThemeUI()

  const responsiveVariant = rv(variant, "excerpt")

  const visibility = responsiveVariant.reduce(
    (mobileVisibility, variant) =>
      mobileVisibility === false &&
      get(context.theme, variant, {}).display === "none"
        ? false
        : true,
    false
  )

  if (show) {
    return (<Text
          className="excerpt"
          variant="small"
          sx={{
            ...styles.excerpt,
            variant: responsiveVariant,
          }}
        >
          {excerpt}
        </Text>)
  }

  if (!omitExcerpt && visibility) {
    return (
      <Text
        className="excerpt"
        variant="small"
        sx={{
          ...styles.excerpt,
          variant: responsiveVariant,
        }}
      >
        {excerpt}
      </Text>
    )
  }
  
  return null
}

export default CardBodyExcerpt
