import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Stack, Main } from "@layout"
import PageTitle from "../components/Shared/PageTitle"
import Divider from "@components/Divider"
import Seo from "@widgets/Seo"
import CardList from "../components/Blog/CardList"
import "./animation.css"

const PublicationsQuery = graphql`
  query PublicationsMarkdowns {
    publication: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "publication" } } }
    ) {
      nodes {
        frontmatter {
          templateKey
          name_author
          date(formatString: "MMMM DD, YYYY")
          excerpt
          title
        }
        fields {
          slug
        }
        id
      }
    }
    author: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "about" } } }
    ) {
      nodes {
        frontmatter {
          templateKey
          date(formatString: "MMMM DD, YYYY")
          avatar
          name
          role
        }
        fields {
          slug
        }
        id
      }
    }
  }
`

export default function () {
  const data = useStaticQuery(PublicationsQuery)

  const publicationsData = data.publication.nodes
  const authorsData = data.author.nodes

  const publications = publicationsData.map((dataItem, idx) => {
    const {
      id,
      frontmatter: { name_author, date, excerpt, title },
      fields: { slug },
    } = dataItem

    const authorData = authorsData.find(
      dataItem => dataItem.fields.slug === `/leadership/${name_author}`
    )

    const {
      frontmatter: { name, avatar, role },
    } = authorData

    return {
      date,
      slug,
      excerpt,
      title,
      id,
      author: {
        slug: authorData.fields.slug,
        title: role,
        name,
        id: authorData.id,
        avatar,
      },
    }
  })

  return (
    <>
      <Seo title="Our Team" />
      <Divider space={3} />
      <div className="animateInDown">
        <PageTitle header="Our Blog" />
      </div>
      <div className="animateInUp">
        <Main style={{ marginBottom: "35px" }}>
          {publications.length > 0 ? (
            <CardList
              nodes={publications}
              variant={["vertical"]}
              columns={[1, 2, 3, 3]}
            />
          ) : (
            "There are no publications"
          )}
        </Main>
      </div>
    </>
  )
}
