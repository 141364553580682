import React from "react"
import { Link as GLink } from "gatsby"
import { Link, useThemeUI, get } from "theme-ui"
import Avatar from "../Shared/Avatar"
import { buildResponsiveVariant as rv } from "@components/utils/"

const authorImageSize = 48
const bgWidth = 60

const CardFooterAuthorAvatar = ({ variant, omitAuthor, author }) => {
  const context = useThemeUI()

  if (omitAuthor) return null

  const responsiveVariant = rv(variant, "authorPhoto")

  const visibility = responsiveVariant.reduce(
    (mobileVisibility, variant) =>
      mobileVisibility === false &&
      get(context.theme, variant, {}).display === "none"
        ? false
        : true,
    false
  )

  return visibility ? (
    author && author.avatar ? (
      <Link
        as={GLink}
        to={author.slug}
        aria-label={author.name}
        sx={{ variant: responsiveVariant }}
      >
        <Avatar
          avatar={author.avatar}
          width={authorImageSize}
          bgWidth={bgWidth}
          simple
        />
      </Link>
    ) : null
  ) : null
}
export default CardFooterAuthorAvatar
